/* src/components/Footer.css */
.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;
}

.footer p {
    font-size: 0.8rem;
    color: #6c757d;
}
