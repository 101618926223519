/* src/components/AboutSection.css */
.about-container {
    max-width: 1000px; /* Set maximum width */
    margin: 0 auto;    /* Center the container */
    padding: 50px 20px;
    box-sizing: border-box;
    text-align: left;  /* Align text to the left */
}

.about-container h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: left;  /* Ensure heading is left-aligned */
}

.about-container p {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: left;  /* Ensure paragraphs are left-aligned */
}
