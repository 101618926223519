/* src/components/Section.css */
section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    padding: 0;
    box-sizing: border-box;
}

#home { background: #f4f4f4; }
#features { background: #ddd; }
#about { background: #bbb; }
#contact { background: #999; }
