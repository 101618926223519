/* src/components/HomeSection.css */
.home-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    /* padding: 20px; */
    padding: 20%;
    box-sizing: border-box;
}

.home-left {
    flex: 1;
    padding: 20px;
    text-align: left;
}

.home-left h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.home-left p {
    font-size: 1.2rem;
    line-height: 1.5;
}

.home-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-right svg {
    width: 80%;
    height: auto;
}
