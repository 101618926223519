/* src/components/FeaturesSection.css */
.features-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
   /* padding: 50px 20px;  Adjust padding as needed */
    padding: 20%;
    box-sizing: border-box;
}

.app-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
}

.app-icon {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    margin-right: 20px;
}

.app-details {
    flex: 1;
}

.app-details h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.app-details p {
    font-size: 1.2rem;
    line-height: 1.6;
}
