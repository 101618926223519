body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
}

main {
  padding-top: 60px;
}
