header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  color: #333;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  display: block;
  border-radius: 5px;
}

nav ul li a.active, nav ul li a:hover {
  color: #ff6347;
  background-color: rgba(255, 99, 71, 0.1);
}
